<template>
  <div class="home page">
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-flag-variant</v-icon>

      البانر الأول

    </h2>
    <hr class="my-5 header-divider" />


    <div class="pa-4 white">
      <v-progress-linear
          v-if="bannerFormLoading"
          color="primary"
          indeterminate
          rounded
          height="6"
      ></v-progress-linear>
      <div v-else>
        <div class="textFields">

          <input type="hidden" name="id" v-model="bannerForm.id" value="1">


          <v-text-field outlined  dense
              :rules="rules"
              v-model="bannerForm.name_ar"
              counter="100"
              label="عنوان البانر بالعربية"
          ></v-text-field>

          <v-spacer></v-spacer>

          <v-text-field  outlined  dense
              :rules="rules"
              v-model="bannerForm.name_en"
              counter="100"
              label="عنوان البانر بالانكليزية"
          ></v-text-field>

          <v-spacer></v-spacer>

          <div class="mb-5">
            <label>من تاريخ</label>
            <input  type="datetime-local" v-model="bannerForm.start_date" :error-messages="errors.start_date" required/>
            <div v-if="errors.start_date" class="mt-2">
              <span color="error">{{ errors.start_date }}</span>
            </div>

            <!-- Add vertical spacing between date inputs -->
            <div class="mb-3"></div>

            <label class="mt-3">الى تاريخ</label>
            <input type="datetime-local" v-model="bannerForm.end_date" :error-messages="errors.end_date" required/>
            <div v-if="errors.end_date" class="mt-2">
              <span color="error">{{ errors.end_date }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>

          <!-- Display the image -->
          <img v-if="bannerForm.img_ar_src || bannerForm.img" :src="bannerForm.img_ar_src || bannerForm.img" alt="Banner Image" style="max-width: 100%; height: auto; max-height: 200px;" />

          <v-spacer></v-spacer>


          <v-file-input
              dense
              outlined
              label="الصورة [Ar] "
              prepend-icon="mdi-image"
              accept="image/*"
              v-model="bannerForm.img"
              :error-messages="errors.img"
              @change="updateImage"
          ></v-file-input>
          <p class="error_class">*accept PNG SIZES 250*250 AND LESS</p>



          <!-- Display the image en -->
          <img v-if="bannerForm.img_en_src || bannerForm.img_en" :src="bannerForm.img_en_src || bannerForm.img_en" alt="Banner Image (EN)" style="max-width: 100%; height: auto; max-height: 200px;" />

          <v-spacer></v-spacer>


          <v-file-input
              dense
              outlined
              label="الصورة [EN] "
              prepend-icon="mdi-image"
              accept="image/*"
              v-model="bannerForm.img_en"
              :error-messages="errors.img_en"
              @change="updateImageEn"
          ></v-file-input>
          <p class="error_class">*accept PNG SIZES 250*250 AND LESS</p>




        </div>


        <!-- SEND BUTTON -->
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn
              @click="saveBanner(bannerForm)"
              large
              color="primary"
          >حفظ
          </v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "Banner1Form",

  data: function () {
    return {
      bannerForm: {
        id: 1,
      },
      errors: {},
      bannerFormLoading: false,
      rules: [
        v => !!v || "هذا الحقل مطلوب",
      ],

    };
  },


  created(){
    this.fetchBanner1Data()
  },
  methods: {
    updateImage() {
      // When a new file is selected, update the img_ar_src to preview the new image
      if (this.bannerForm.img) {
        const fileReader = new FileReader();

        fileReader.onload = (e) => {
          this.$set(this.bannerForm, 'img_ar_src', e.target.result);
        };

        fileReader.readAsDataURL(this.bannerForm.img);
      }
    },
    updateImageEn() {
      // When a new file is selected, update the img_src en to preview the new image en
      if (this.bannerForm.img_en) {
        const fileReader = new FileReader();

        fileReader.onload = (e) => {
          this.$set(this.bannerForm, 'img_en_src', e.target.result);
        };

        fileReader.readAsDataURL(this.bannerForm.img_en);
      }
    },

    saveBanner(bannerForm) {
      this.bannerFormLoading = true;
      let formData = new FormData();

      // Append form data to FormData
      for (let key in bannerForm) {
        formData.append(key, bannerForm[key]);
      }

      formData.append('id', bannerForm.id);

      this.axios
          .post("admin/banners/create", formData)
          .then((response) => {
            this.$bus.$emit("showSnackbar", {
              text: "تم حفظ البانر بنجاح",
              color: "success",
            });

            console.log(response);

            this.$set(this.bannerForm, 'img_ar_src', response.data.img_ar_url);
            this.$set(this.bannerForm, 'img_en_src', response.data.img_en_url);
          })


          .catch((error) => {


            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
            }
            for (let key in error.response.data.errors) {

              this.$bus.$emit("showSnackbar", {
                text: error.response.data.errors[key][0],
                color: "error",
              });
            }
          })
          .finally(() => {
            this.bannerFormLoading = false;
          });
    },

    fetchBanner1Data() {
      this.bannerFormLoading = true;

      this.axios.get(`/admin/banners/${1}`)
          .then((response) => {
            console.log(response.data);
            this.$set(this.bannerForm, 'name_ar', response.data.name_ar);
            this.$set(this.bannerForm, 'name_en', response.data.name_en);
            this.$set(this.bannerForm, 'start_date', response.data.start_date);
            this.$set(this.bannerForm, 'end_date', response.data.end_date);
            this.$set(this.bannerForm, 'img_ar_src', response.data.img_ar_url);
            this.$set(this.bannerForm, 'img_en_src', response.data.img_en_url);
          })
          .catch((error) => {
            this.$bus.$emit("showSnackbar", {
              text: "There was a mistake when rendering the page.",
              color: "red darken-3",
            });
          })
          .finally(() => {
            this.bannerFormLoading = false;
          });
    },
  },
};
</script>
<style scoped>
.textFields {
  margin-bottom: 40px;
}
</style>
