<template>
  <div class="root-Users" width="100%" height="200">
    <v-card flat max-width="100%" tile>
      <v-app-bar class="Users" height="150" color="#F9F9F9" flat>
         <h2 class="accent--text">
           <v-icon color="primary" class="ml-3" large>mdi-view-carousel</v-icon>

           اللوحات الإعلانية

    </h2>
        <back-button />
    <hr class="my-5 header-divider" />

      </v-app-bar>
    </v-card>

    <div class="table-home">

        <component v-if="showBanner1" :is="target1component" />
        <component v-if="showBanner2" :is="target2component" />

    </div>
  </div>
</template>

<script>
import Banner1Form from "./Forms/Banner1Form.vue";
import Banner2Form from "./Forms/Banner2Form.vue";

export default {
  name: "Banners",
  data() {
    return {
      target1component: "Banner1Form",
      target2component: "Banner2Form",
      showBanner1: true,
      showBanner2: true,
    };
  },
  components: {
    Banner1Form,
    Banner2Form
  },
};
</script>
<style>
* {
  font-family: "Leelawadee";
}
.root-Users {
  margin: -20px;
}
.Users {
  padding-right: 40px;
  padding-left: 40px;
}
</style>
